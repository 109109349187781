<template>
  <vs-col
    vs-w="11"
    vs-type="flex"
    vs-justify="flex-start"
    vs-align="center"
    v-if="row!== undefined && row.ActionTypeName != 'ChangeShippingNamingActionModel' || row.ShipOptionsId==35  "
  >Unsupported action please contact support.</vs-col>
  <vs-col v-else-if="row!== undefined" vs-w="11">
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <div>
          Change&nbsp;
          <b>Title</b>
          &nbsp; &nbsp;&nbsp;
        </div>
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <div>
          <sb-text-input
            v-model="row.ShipOptionNameChange"
            placeholder="Title.."
            :type="text"
            :maxlength="50"
            class="input-long"
            :disabled="useDefaultTitle"
          ></sb-text-input>
        </div>&nbsp;&nbsp;
        <sb-checkbox
          v-model="useDefaultTitle"
          @change="toggleDefaultTitle($event)"
        >Use default title</sb-checkbox>&nbsp;
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        Change &nbsp;
        <b>Description</b> &nbsp;&nbsp;
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <div>
          <sb-text-input
            :disabled="useDefaultDescription"
            class="input-long"
            v-model="row.ShipOptionDescriptionChange"
            placeholder="Description.."
            :type="text"
            :maxlength="90"
          ></sb-text-input>
        </div>&nbsp;&nbsp;
        <sb-checkbox
          v-model="useDefaultDescription"
          @change="toggleDefaultDescription"
        >Use default description</sb-checkbox>&nbsp;
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        Apply to &nbsp;
        <b>Shipping Method</b>&nbsp;
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <sb-dropdown
          :options="ShippingMethodsList"
          v-model="row.ShipOptionsId"
          placeholder="Shipping Method.."
          class="select-size-200"
        >
          <sb-selected-option
            slot="selection"
            slot-scope="{ selected }"
          >{{getSelectedLabel(ShippingMethodsList,selected)}}</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option.value">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
      </vs-col>
    </vs-row>
  </vs-col>
</template>
<script>
let ShippingMethods = require("@/enums/shippingMethods");
let ActionsList = require("@/enums/actionsList");

export default {
  props: ["row"],

  data() {
    return {
      ShippingMethodsList: ShippingMethods.default.ShippingMethods,
      actionsList: ActionsList.default.ActionsList,
      actionModel: ActionsList.default,
      ActiveShippingMethods: [],
      useDefaultTitle: true,
      useDefaultDescription: true 
    };
  },
  mounted() { 
    this.populateActiveShippingMethod();
    this.useDefaultTitle =
      this.row.ShipOptionNameChange === undefined ||
      this.row.ShipOptionNameChange === null
        ? true
        : false;
    this.useDefaultDescription =
      this.row.ShipOptionDescriptionChange === undefined ||
      this.row.ShipOptionDescriptionChange === null
        ? true
        : false; 
  },
  computed: {},
  methods: {
    getSelectedLabel(options, value) {
      var option = options.find(x => x.value === value);
      if (option == undefined || option.text == "") {
        option = options.find(x => x.value === value.value);
      }
      return option !== undefined ? option.text : "";
    },
    populateActiveShippingMethod() {
      // if(this.$store.state.shippingMethods!=undefined){
      //   this.$store.state.shippingMethods.forEach(function(item,index){
      //       this.ActiveShippingMethods.push(
      //         {
      //           value: item.ShipOptionId,
      //           text: ??
      //         }
      //       );
      //   });
      //  }
    },
    nameTypeChanged() {},
    descriptionTypeChanged() {},
    toggleDefaultTitle(e) {
      if (e == false) {
        this.row.ShipOptionNameChange = "";
      } else {
        this.row.ShipOptionNameChange = null;
      }
    },
    toggleDefaultDescription(e) {
      if (e == false) {
        this.row.ShipOptionDescriptionChange = "";
      } else {
        this.row.ShipOptionDescriptionChange = null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.input-long {
  min-width: 300px;
}
.select-size-200 {
  margin-left: 0px !important;
}
</style>