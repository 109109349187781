<template>
  <div  >
    <sb-banner ref="admin-bar" variation="warning" class="admin-bar">
        <sb-flex-wrapped class="admin-bar-content">
            <span>
                <span>
                    Logged in as: <b>{{ this.impersonatedUser.Email }}</b>
                </span>
                <span class="spacing">
                    UserId : <b>{{ this.impersonatedUser.UserId }}</b>
                </span>
            </span>
            <span>              
               <a :href="app_link" class="spacing" >Change User</a>
            </span>
        </sb-flex-wrapped>
    </sb-banner>
    <div class="admin-mask">
      <div class="mask-top" :style="maskTop"></div>
      <div class="mask-right" :style="maskTop"></div>
      <div class="mask-bottom"></div>
      <div class="mask-left" :style="maskTop"></div>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle' 

export default {
  name: 'AdminBar',
  props: {
    offset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      adminPanelHeight: 0,
      resizeHandler: throttle(this.calculateAdminPanelBounds, 500), 
      impersonatedUser: this.$store.getters['impersonatedUser'],
      app_link: process.env.VUE_APP_SHIPBOB_LINK+'admin/',
    }
  },
  mounted () {   
    window.addEventListener('resize', this.resizeHandler)
    this.domCycle(this.calculateAdminPanelBounds)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: { 
    maskTop () {
      const top = `${this.adminPanelHeight}px`
      return { top }
    }
  },
  methods: {
    
    calculateAdminPanelBounds () {
      this.adminPanelHeight = this.$refs['admin-bar'].$el.offsetHeight
      this.$emit('update:offset', this.adminPanelHeight)
    },
    domCycle (func) {
      this.$nextTick(() => {
        func()
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.admin-mask {
  $line-width: 2px;
  .mask-top, .mask-right, .mask-bottom, .mask-left {
    position: fixed;
    background-color: rgb(231, 29, 22);
    z-index: 3;
  }
  .mask-top, .mask-bottom {
    width: 100vw;
    height: $line-width;
  }
  .mask-right, .mask-left {
    top: 0;
    height: 100vh;
    width: $line-width;
  }
  .mask-top { top: 0; }
  .mask-right { right: 0; }
  .mask-bottom { bottom: 0; }
  .mask-left { left: 0; }
}
.admin-bar {
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  height:40px; 
  display: flex;
  align-items: center;
  .admin-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      word-break: break-word;
    }
  }
    .spacing{
        padding-left:10px;
    }
}
</style>
