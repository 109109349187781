export const DESCENDING = 'desc'
export const ASCENDING = 'asc'
const sortDelimiter = ' '
const filterDelimiter = sortDelimiter
const displayFilterDelimiter = ':';

export default class TableHelper  { 
     
    constructor (itemmapper,requestedPageSize) { 
        this.mapper = itemmapper; 
        this.receivedData = [];
        this.pagedData =  [];
        this.pageSize = requestedPageSize;
        this.pagination = {
                pageSize:requestedPageSize,
                currentPage:1,
                totalPages:1,
                totalRecords:0,
                visibleRecords:30
            };
      } 

        handleSort ({ sortColumn, descending }) {
            //descending is either true or false      
           const sortFieldName = sortColumn.field;
           var sortOrder = descending ? "-" : ""; 
           this.receivedData.sort(this.internalSort(sortOrder+sortFieldName)); 
           this.loadData(this.receivedData, this.pagination.pageNumber);
         } 

         internalSort(property) {
            var sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers, 
                * and you may want to customize it to your needs
                */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        } 

        preLoadReset()
        {
            this.receivedData = [];
            this.pagedData = [];
        }

    async loadData(data, pageNumber)
    {   
        await new Promise(res => {
     
            this.receivedData=data;  
            this.pagedData = [];
            if (pageNumber==undefined){pageNumber=1;}
            var page = this.paginate(this.receivedData,this.pageSize,pageNumber); 
            this.pagination.currentPage = pageNumber;
            this.pagination.totalPages =Math.ceil(this.receivedData.length/this.pageSize);
            this.pagination.totalRecords =this.receivedData.length; 
            this.pagination.visibleRecords = page.length; 
             page.forEach((item) => { 
                this.pagedData.push(
                    this.mapper.map(item) 
              );
            }); 
            res(true); 
      });
    } 
    dateFormat(date) {
        return moment(new Date(date)).format('MM-DD-YYYY');
    }
    numberWithCommas(x) {
        return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    paginate (array, page_size, page_number) {
      --page_number; // because pages logically start with 1, but technically with 0
      return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }
       
}  