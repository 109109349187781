<template>
  <Splash
    id="loader-splash"
    :message="message"
  />
</template>

<script>
import { getAuthService } from '../auth';

export default {
  name: 'AuthorizationCallback',
  data() {
    return {
      message: 'Requesting Auth Token',
    };
  },
  async beforeCreate() {
    try {
      await getAuthService().processCallback();
      this.$router.push({ name: 'Shipping Settings' });
    } catch (error) {
      console.error(`Could not exchange for token: ${error.message}`);
      this.$router.push({ name: 'ExpressUi.Auth' });
    }
  },
};
</script>

<style lang="scss">
  #loader-splash {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
</style>
