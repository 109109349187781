export default{
    ActionObjects: [
        {
            "ActionTypeName":"ChangeShippingNamingActionModel",
            "ShipOptionsId":0,
            "ShipOptionNameChange": "",
            "ShipOptionDescriptionChange": ""
          },
          {
            "ActionTypeName":"FlatRateShippingCostOverrideModel",
            "ShippingOptionIdToOverride":0,
            "ShippingOptionPriceOverride": 0
          },
          {
            "ActionTypeName":"CarrierServiceMarkupModel",
            "ShipOptionId":0,
            "isDollarAmount": false,
            "MarkUpAmount":0
          },
          {
            "ActionTypeName":"RemoveShippingMethodActionModel",
            "ShippingOptionId":0  
          },
          {
            "ActionTypeName":"RemoveShippingOptionBasedOnAvailableOptions",
            "ShipOptionsToRemove":[], 
            "ShipOptionsToKeep":[]
          } 
     ]
 }