export default{
   ConditionObjects: [
        {
            "ConditionTypeName":"PropertyBasedConditionsModels",
            "DataTypeEnum":"1",
            "FieldToCheck":"CheckoutRequest.CartValue",
            "Operator": "gteq",
            "ValueToCheck": 0,
            "Negate":false
         },
         {
            "ConditionTypeName":"PropertyBasedConditionsModels",
            "DataTypeEnum":"1",
            "FieldToCheck":"CheckoutRequest.CartWeightOz",
            "Operator": "gteq",
            "ValueToCheck": 0,
            "Negate":false 
         },
         {
            "ConditionTypeName":"PropertyBasedConditionsModels",
            "DataTypeEnum":"0",
            "FieldToCheck":"CheckoutRequest.Destination.Country",
            "Operator": "eq",
            "ValueToCheck": "",
            "Negate":false
         },
         {
            "ConditionTypeName":"PropertyBasedConditionsModels",
            "DataTypeEnum":"0",
            "FieldToCheck":"CheckoutRequest.Destination.Province",
            "Operator": "eq",
            "ValueToCheck": "" ,
            "Negate":false
         }
    ],
    ConditionFieldToCheckList: [
        {             
            "value": "CheckoutRequest.CartValue",
            "text": "Cart Value"
         },
         { 
            "value":"CheckoutRequest.CartWeightOz", 
            "text": "Cart Weight Oz"
         },
         { 
            "value":"CheckoutRequest.Destination.Country", 
            "text": "Destination Country"
         },
         { 
            "value":"CheckoutRequest.Destination.Province", 
            "text": "Destination Province"
         }
    ],
    NumericOperatorDropDown: [
        {
          value: "eq",
          text: "equal to"
        },
        {
          value: "gt",
          text: "greater than"
        },
        {
          value: "gteq",
          text: "greater than or equal to"
        },
        {
          value: "lt",
          text: "less than"
        },
        {
          value: "lteq",
          text: "less than or equal to"
        }
      ],
      StringOperatorDropDown: [
        {
          value: "eq",
          text: "equal to"
        },
        {
            value: "neq",
            text: "not equal to"
        }
      ]
}