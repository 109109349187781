export default {
  ShippingMethods:  [ 
  {value: 1, text: 'Overnight', description:'(UPS overnight)' , price:0, shipOptionMappingText: 'Overnight'},
  {value: 2, text: 'Expedited', description:'(UPS 2nd Day Air, USPS Express)' , price:0, shipOptionMappingText: 'Expedited'},
  {value: 3, text: 'Standard', description:'(USPS First Class & Priority, UPS Ground)' , price:0, shipOptionMappingText: 'Standard'},
  {value: 4, text: 'Priority', description:'USPS Priority 2-5 Business Days' , price:0, shipOptionMappingText: 'Priority'},
  {value: 30, text: '2 Day Express 30', description:'Orders placed before noon are processed same-day, and arrive in two business days' , price:10.00, shipOptionMappingText: '2 Day Express'},
  {value: 36, text: '2 Day Express 36', description:'Orders placed before noon are processed same-day, and arrive in two business days' , price:10.00, shipOptionMappingText: '2-Day Express'}   ], 
  DefaultRulesV2:[ 
    {
      "carrierServiceRuleType":1,
      "ruleId": "",
      "ruleName": "Default Title 2 Day Express",     
      "ruleConditionTypeEnum":"Always", 
      "active":true,
      "entityType":"CarrierService",
      "actions":[ 
        // {
        //   "ActionTypeName":"ChangeShippingNamingActionModel",
        //   "ShipOptionsId":30,
        //   "ShipOptionNameChange": "2 Day Express",
        //   "ShipOptionDescriptionChange": "Orders placed before noon are processed same-day, and arrive in two business days"
        // }
      ],
      "conditions":
      [
          {
             "ConditionTypeName":"ConstantTrueRuleConditionModels"  
          }
      ]
  } ,
  {
    "carrierServiceRuleType":2,
    "ruleId": "",
    "ruleName": "",     
    "ruleConditionTypeEnum":"Always",  
    "active":true,
    "entityType":"CarrierService",
    "actions":[ 
      // {
      //   "ActionTypeName":"FlatRateShippingCostOverrideModel",
      //       "ShippingOptionIdToOverride":30,
      //       "ShippingOptionPriceOverride": 10.00
      // }
    ],
    "conditions":
    [
        {
           "ConditionTypeName":"ConstantTrueRuleConditionModels"  
        }
    ]
} ,
    {
      "carrierServiceRuleType":3,
      "ruleId": "",
        "ruleName": "",
        "ruleConditionTypeEnum":"When", 
        "active":true,
        "entityType":"CarrierService",
        "actions":[
          // {
          //   "ActionTypeName":"FlatRateShippingCostOverrideModel",
          //   "ShippingOptionIdToOverride":30,
          //   "ShippingOptionPriceOverride": 10.00
          // },
          // {
          //   "ActionTypeName":"ChangeShippingNamingActionModel",
          //   "ShipOptionsId":30,
          //   "ShipOptionNameChange": "2 Day Express",
          //   "ShipOptionDescriptionChange": "Orders placed before noon are processed same-day, and arrive in two business days"
          // }
        ],
        "conditions":
        [
            {
               "ConditionTypeName":"PropertyBasedConditionsModels",
               "DataTypeEnum":"1",
               "FieldToCheck":"CheckoutRequest.CartValue",
               "Operator": "eq",
               "ValueToCheck": 0
            }
        ]
    } 
    
]
}