import DashboardV2 from "../../components/DashboardV2";
import ShippingSettings from "../../components/ShippingSettings";
import Rules from "../../components/Rules";

const expressUiRoutes = [
  {
    path: "/dashboard",
    component: DashboardV2,
    name: "Dashboard",
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/shipping",
    component: ShippingSettings,
    name: "Shipping Settings",
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/rules/shipping/:id",
    component: Rules,
    name: "Shipping Method Rules",
    meta: {
      requiresAuth: true,
    }
  },
];

export { expressUiRoutes };
