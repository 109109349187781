<template>
   

  <vs-col
    vs-w="11"
    vs-type="flex"
    vs-justify="flex-start"
    vs-align="center"
    v-if="row === undefined || row.ConditionJoinOperator == 'or'"
  >**Unsupported condition with an Or statement please contact support.**</vs-col>
  <vs-col v-else-if="row!== undefined" vs-w="11">
   
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        If &nbsp; 
        <b> {{getSelectedLabel(RuleConditionObjects.ConditionFieldToCheckList,this.row.FieldToCheck)}}</b> &nbsp;&nbsp;
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <sb-dropdown
        :options="RuleConditionObjects.NumericOperatorDropDown"
        v-model="row.Operator"
        placeholder="Select.."
        v-if="row.DataTypeEnum==1"
        class="select-size-200"
      >
        <sb-selected-option
          slot="selection"
          slot-scope="{ selected }"
        >{{getSelectedLabel(RuleConditionObjects.NumericOperatorDropDown,selected)}}</sb-selected-option>
        <sb-options>
          <sb-option slot-scope="{ option }" :value="option.value">{{ option.text }}</sb-option>
        </sb-options>
      </sb-dropdown>
         <sb-dropdown
        :options="RuleConditionObjects.StringOperatorDropDown"
        v-model="row.Operator"
        placeholder="Select.."
        v-if="row.DataTypeEnum==0"
        class="select-size-200"
      >
        <sb-selected-option
          slot="selection"
          slot-scope="{ selected }"
        >{{getSelectedLabel(RuleConditionObjects.StringOperatorDropDown,selected)}}</sb-selected-option>
        <sb-options>
          <sb-option slot-scope="{ option }" :value="option.value">{{ option.text }}</sb-option>
        </sb-options>
      </sb-dropdown>

      <vs-input v-if="row.DataTypeEnum==0" v-model="row.ValueToCheck"></vs-input>
      <vs-input v-if="row.DataTypeEnum==1" v-model="row.ValueToCheck"></vs-input>
      </vs-col>
    </vs-row>
     
  </vs-col>

</template>
<script>
let RuleConditionObjectList = require("@/enums/conditionObjects");

export default {
  props: ["row"],

  data() {
    return {
      RuleConditionObjects: RuleConditionObjectList.default,
      ConditionFieldToCheckSelector: []
    };
  },
  mounted() {},
  computed: {},
  methods: {
    getSelectedLabel(options, value) {
      var option = options.find(x => x.value === value);
      if (option == undefined || option.text == "") {
        option = options.find(x => x.value === value.value);
      }
      return option !== undefined ? option.text : "";
    }
  }
};
</script>
<style lang="scss" scoped>
.input-long {
  min-width: 300px;
}
.select-size-200 {
  margin-left: 0px !important;
}
</style>