<template>
    
  <vs-col
    vs-w="11"
    vs-type="flex"
    vs-justify="flex-start"
    vs-align="center"
     v-if="row!== undefined && row.ActionTypeName != 'RemoveShippingOptionBasedOnAvailableOptions' || row.ShipOptionsId==35  "
  >Unsupported action please contact support.</vs-col>
  <vs-col v-else-if="row!== undefined" vs-w="11">
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <b>When</b>&nbsp; these are present &nbsp;
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <sb-dropdown
          :options="ShippingMethodsList"
          v-model="selectOptionsShipOptionsToKeep"
          class="select-size-200"
          placeholder="Select shipping methods"
        >
          <sb-selected-option
            slot="selection"
            slot-scope="{ selected }"
          >{{selectOptions1ShipOptionsToKeep.length }} Filters Selected</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
  <SbDismissibleChip
          v-for="f of selectOptions1ShipOptionsToKeep"
          v-on:close="removeSelectedOptionKeep(f)"
          class="SbDismissibleChip-wrapper"
        >{{f.text}}</SbDismissibleChip>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <b>Hide</b>&nbsp; these &nbsp;
      </vs-col>
      <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <sb-dropdown
          :options="ShippingMethodsList"
          v-model="selectOptionsShipOptionsToRemove"
          class="select-size-200"
          placeholder="Select shipping methods"
        >
          <sb-selected-option
            slot="selection"
            slot-scope="{ selected }"
          >{{selectOptions1ShipOptionsToRemove.length }} Filters Selected</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
 <SbDismissibleChip
          v-for="f of selectOptions1ShipOptionsToRemove"
          v-on:close="removeSelectedOption(f)"
          class="SbDismissibleChip-wrapper"
        >{{f.text}}</SbDismissibleChip>
      </vs-col>
    </vs-row>
     
  </vs-col>
</template>
<script>
let ShippingMethods = require("@/enums/shippingMethods");
let ActionsList = require("@/enums/actionsList");

export default {
  props: ["row"],

  data() {
    return {
      ShippingMethodsList: ShippingMethods.default.ShippingMethods,
      actionsList: ActionsList.default.ActionsList,
      actionModel: ActionsList.default,
      selectOptions1ShipOptionsToRemove: [],
      selectOptions1ShipOptionsToKeep: [],
      options1: []
    };
  },
  mounted() { 
    //load the data 
    this.row.ShipOptionsToRemove.forEach(f=>{
      var option = this.ShippingMethodsList.find(x => x.value === f) 
      var item =Object.assign({}, this.ShippingMethodsList.find(x => x.value === f));
        this.selectOptions1ShipOptionsToRemove.push(item); 
    });

     this.row.ShipOptionsToKeep.forEach(f=>{
      var option = this.ShippingMethodsList.find(x => x.value === f) 
      var item =Object.assign({}, this.ShippingMethodsList.find(x => x.value === f));
        this.selectOptions1ShipOptionsToKeep.push(item); 
    });
   
  },
  computed: {
    selectOptionsShipOptionsToRemove: {
      get() {
        return this.selectOptions1ShipOptionsToRemove;
      },
      set(selection) {
        this.addSelection(selection);
      }
    },
     selectOptionsShipOptionsToKeep: {
      get() {
        return this.selectOptions1ShipOptionsToKeep;
      },
      set(selection) {
        this.addSelectionKeep(selection);
      }
    }
  },
  methods: {
    getSelectedLabel(options, value) {
      var option = options.find(x => x.value === value);
      if (option == undefined || option.text == "") {
        option = options.find(x => x.value === value.value);
      }
      return option !== undefined ? option.text : "";
    },
    removeSelectedOption(filter) {
      const index = this.selectOptions1ShipOptionsToRemove.findIndex(f => f.text == filter.text);
      this.selectOptions1ShipOptionsToRemove.splice(index, 1);
      this.row.ShipOptionsToRemove =  this.selectOptions1ShipOptionsToRemove.map(a => a.value);
    },
    addSelection(selection) {
      this.selectOptions1ShipOptionsToRemove = [];
      selection.forEach(item => {
        this.selectOptions1ShipOptionsToRemove.push(item);
      });
      this.row.ShipOptionsToRemove =  this.selectOptions1ShipOptionsToRemove.map(a => a.value);
    },
    removeSelectedOptionKeep(filter) {
      const index = this.selectOptions1ShipOptionsToKeep.findIndex(f => f.text == filter.text);
      this.selectOptions1ShipOptionsToKeep.splice(index, 1);
      this.row.ShipOptionsToKeep =  this.selectOptions1ShipOptionsToKeep.map(a => a.value);  
    },
    addSelectionKeep(selection) {
      this.selectOptions1ShipOptionsToKeep = [];
      selection.forEach(item => {
        this.selectOptions1ShipOptionsToKeep.push(item);
      });
      this.row.ShipOptionsToKeep =  this.selectOptions1ShipOptionsToKeep.map(a => a.value); 
    }
  }
};
</script>
<style lang="scss" scoped>
.input-long {
  min-width: 300px;
}
.select-size-200 {
  margin-left: 0px !important;
}
.SbDismissibleChip-wrapper {
  margin-bottom: 0px;
}
</style>