import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=9a6abc9a&scoped=true"
import script from "./BaseFooter.vue?vue&type=script&lang=js"
export * from "./BaseFooter.vue?vue&type=script&lang=js"
import style0 from "./BaseFooter.vue?vue&type=style&index=0&id=9a6abc9a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a6abc9a",
  null
  
)

export default component.exports