import { getAuthService } from '@/auth/index';
import AuthorizationCallback from "@/components/AuthorizationCallback";

const authRoutes = [
  {
    path: '/',
    name: 'ExpressUi.Auth',
    component: null,
    beforeEnter: async (to, _from, next) => {
      const access_token = await getAuthService().getToken();

      if (!access_token) {
        await getAuthService().login();
      } else {
        next({ name: 'Shipping Settings' });
      }
    },
  },
  {
    path: '/signin-callback',
    name: 'ExpressUi.Auth.Callback',
    component: AuthorizationCallback,
  },
];

export { authRoutes };
