<template>
  <div v-if="this.data !== undefined" style="padding-bottom:70px;">
    <div>
      <sb-panel
        variation="default"
        style="flex-direction: column;"
        v-if="this.data.ruleConditionTypeEnum == RuleConditionTypes.Always"
      >
        <div style="width:100%;">
          <base-header
            :heading="'Always True'"
            :description="'This type of rule has no conditions, we will always do whats in the actions panel.'"
            slot="header"
          ></base-header>
        </div>
      </sb-panel>
       
      <sb-panel
        variation="default"
        v-if="this.data.ruleConditionTypeEnum == RuleConditionTypes.When"
      >
        <div
          style="width:100%;"
          v-if="this.data !== undefined && this.data.conditions !== undefined"
        >
          <base-header
            class="h2header"
            :heading="'Conditional'"
            :description="'If the below conditions are met, we will do whats in the actions panel.  *Please Note* If you have existing conditions which are unsupported please open a ticket'"
            slot="header"
          >
            <div slot="action">
              <popover
                :open="true"
                :titleText="'Add Condition'"
                :controlClass="'btn btn-primary dropdown-toggle'"
                :nonAdminOptions="this.nonAdminConditionOptionsList"
              ></popover>
               
            </div>
          </base-header> 
          <div style="width:100%;" v-if="this.data.conditions.length===0"> 
            <vs-row vs-type="flex" vs-justify="space-between">
              <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                <div class="emptystate">
                  <h4>Add a condition using the dropdown</h4>
                </div>
              </vs-col>
            </vs-row>
          </div>
          <vs-row
            v-else-if="this.data.conditions.length>0"
            vs-type="flex"
            vs-justify="flex-start"
            v-for="(row, index) in this.data.conditions"
            v-bind:key="row.RuleStatementId"
          >
            <vs-col
              vs-w="11"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              v-if="row=== undefined"
            >Unsupported condition please contact support.</vs-col>
            <vs-col
              vs-w="11"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              v-else-if="row!== undefined"
            >

            <condition-property-value   :row="row"></condition-property-value>
           
            
            </vs-col>
            <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
              <sb-button
                variation="warning"
                size="extra-small"
                @click="removeCondition(row)"
                @click.prevent
              >Remove</sb-button>
            </vs-col>
          </vs-row>
            
          <!-- <vs-row >
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center"> 
              <popover
                :open="true"
                :titleText="'And If '"
                :controlClass="'btn btn-primary dropdown-toggle'"
                :nonAdminOptions="this.nonAdminConditionOptionsList"
              ></popover>
             </vs-col>
            </vs-row>
             -->
        </div>
      </sb-panel>
    </div>
    <vs-row vs-w="12">
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="flex-end">
        <span style="padding:20px;" class="divider-vertical"></span>
      </vs-col>
    </vs-row>

    <sb-panel variation="default">
      <div style="width:100%;" v-if="this.data !== undefined && this.data.actions !== undefined">
        <base-header :heading="'Actions'" :description="''" slot="header">
          <div slot="action">
            <popover
              :open="true"
              :titleText="'Add Action'"
              :controlClass="'btn btn-primary dropdown-toggle'"
              :nonAdminOptions="this.nonAdminActionOptionsList"
            ></popover>
          </div>
        </base-header>
        <div style="width:100%;" v-if="this.data.actions.length===0">
          <vs-row vs-type="flex" vs-justify="space-between">
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
              <h4>Add an action using the dropdown</h4>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          v-else
          vs-type="flex"
          vs-justify="space-between"
          v-for="(row, index) in this.data.actions"
          v-bind:key="row.ActionId"
        >
          <vs-divider></vs-divider>
          <action-markup-shipping-price
            v-if="row.ActionTypeName=='CarrierServiceMarkupModel'"
            :row="row"
          ></action-markup-shipping-price>
          <action-shipping-price
            v-if="row.ActionTypeName=='FlatRateShippingCostOverrideModel'"
            :row="row"
          ></action-shipping-price>
          <action-title v-if="row.ActionTypeName=='ChangeShippingNamingActionModel'" :row="row"></action-title>
          <action-remove-shipping-method v-if="row.ActionTypeName=='RemoveShippingMethodActionModel'" :row="row"></action-remove-shipping-method>
          <action-remove-shipping-option-based-on-available-options v-if="row.ActionTypeName=='RemoveShippingOptionBasedOnAvailableOptions'" :row="row"></action-remove-shipping-option-based-on-available-options>
          
          <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
            <sb-button
              variation="warning"
              size="extra-small"
              @click="removeAction(row)"
              @click.prevent
            >Remove</sb-button>
          </vs-col>
        </vs-row>
      </div>
    </sb-panel>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import Vue from "vue";
let RuleConditionTypesEnums = require("@/enums/ruleConditionType");
let RuleConditionObjectList = require("@/enums/conditionObjects");
let RuleActionObjectList = require("@/enums/actionObjects");
let RuleActionList = require("@/enums/actionsList");

export default {
  props: ["data"],
  components: {},
  data() {
    return {
      item: this.data,
      RuleConditionTypes: RuleConditionTypesEnums.default,
      RuleConditionObjects: RuleConditionObjectList.default,
      RuleActionObjects: RuleActionObjectList.default,
      RuleActionList: RuleActionList.default,
       
      nonAdminConditionOptionsList: [
        {
          Id: "CheckoutRequest.CartValue",
          Title: "If Cart Value is",
          Callback: async _ => {
            var newCondition = Object.assign(
              {},
              this.RuleConditionObjects.ConditionObjects.find(
                f =>
                  f.ConditionTypeName == "PropertyBasedConditionsModels" &&
                  f.FieldToCheck == "CheckoutRequest.CartValue"
              )
            ); //clone
            this.data.conditions.push(newCondition);
          },
          isDisabled: _ => false
        },
        {
          Id: "CheckoutRequest.CartWeightOz",
          Title: "If Cart Weight Oz is",
          Callback: async _ => {
            var newCondition = Object.assign(
              {},
              this.RuleConditionObjects.ConditionObjects.find(
                f =>
                  f.ConditionTypeName == "PropertyBasedConditionsModels" &&
                  f.FieldToCheck == "CheckoutRequest.CartWeightOz"
              )
            ); //clone
            this.data.conditions.push(newCondition);
          },
          isDisabled: _ => false
        },
        {
          Id: "CheckoutRequest.Destination.Country",
          Title: "If Destination Country is",
          Callback: async _ => {
            var newCondition = Object.assign(
              {},
              this.RuleConditionObjects.ConditionObjects.find(
                f =>
                  f.ConditionTypeName == "PropertyBasedConditionsModels" &&
                  f.FieldToCheck == "CheckoutRequest.Destination.Country"
              )
            ); //clone
            this.data.conditions.push(newCondition);
          },
          isDisabled: _ => false
        },
        {
          Id: "CheckoutRequest.Destination.Province",
          Title: "If Destination State/Province is",
          Callback: async _ => {
            var newCondition = Object.assign(
              {},
              this.RuleConditionObjects.ConditionObjects.find(
                f =>
                  f.ConditionTypeName == "PropertyBasedConditionsModels" &&
                  f.FieldToCheck == "CheckoutRequest.Destination.Province"
              )
            ); //clone
            this.data.conditions.push(newCondition);
          },
          isDisabled: _ => false
        }
         
      ],
      nonAdminActionOptionsList: [
        {
          Id: "ChangeShippingNamingActionModel",
          Title: "Change Title or Description",
          Callback: async _ => {
            var newAction = Object.assign(
              {},
              this.RuleActionObjects.ActionObjects.find(
                f => f.ActionTypeName == "ChangeShippingNamingActionModel"
              )
            ); //clone
            this.data.actions.push(newAction);
          },
          isDisabled: _ => false
        },
        {
          Id: "FlatRateShippingCostOverrideModel",
          Title: "Set Flat Rate Shipping Price",
          Callback: async _ => {
            var newAction = Object.assign(
              {},
              this.RuleActionObjects.ActionObjects.find(
                f => f.ActionTypeName == "FlatRateShippingCostOverrideModel"
              )
            ); //clone
            this.data.actions.push(newAction);
          },
          isDisabled: _ => false
        },
        {
          Id: "CarrierServiceMarkupModel",
          Title: "Markup Shipping Price",
          Callback: async _ => {
            var newAction = Object.assign(
              {},
              this.RuleActionObjects.ActionObjects.find(
                f => f.ActionTypeName == "CarrierServiceMarkupModel"
              )
            ); //clone
            this.data.actions.push(newAction);
          },
          isDisabled: _ => false
        },
        {
          Id: "RemoveShippingMethodActionModel",
          Title:  "Hide a Shipping Method"  ,
          Callback: async _ => {
            var newAction = Object.assign(
              {},
              this.RuleActionObjects.ActionObjects.find(
                f => f.ActionTypeName == "RemoveShippingMethodActionModel"
              )
            ); //clone
            this.data.actions.push(newAction);
          },
          isDisabled: _ => false
        },
        {
          Id: "RemoveShippingOptionBasedOnAvailableOptions",
          Title:  "Hide multiple Shipping Methods when",
          Callback: async _ => {
            var newAction = Object.assign(
              {},
              this.RuleActionObjects.ActionObjects.find(
                f => f.ActionTypeName == "RemoveShippingOptionBasedOnAvailableOptions"
              )
            ); //clone
            this.data.actions.push(newAction);
          },
          isDisabled: _ => false
        }
      ]
    };
  },
  mounted() {
    
  },
  computed: {},
  methods: {
    numberWithCommas(x) {
      return x == undefined
        ? x
        : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    update() {},
    getSelectedLabel(options, value) {
      var option = options.find(x => x.value === value);
      if (option == undefined || option.text == "") {
        option = options.find(x => x.value === value.value);
      }
      return option !== undefined ? option.text : "";
    },
    fetch: async function(p) {
      await new Promise(res => {
        res(true);
      });
    }, 
    removeCondition(row) {
      this.data.conditions.splice(this.data.conditions.indexOf(row), 1);
    },
    removeAction(row) {
      this.data.actions.splice(this.data.actions.indexOf(row), 1);
    }
  }
};
</script>
<style lang="scss" scoped>
// @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
.fulfillment-metrics-container {
  padding-bottom: 0px;
}
.link-more-details-paragraph {
  font-size: 15px;
  color: rgb(41, 103, 165);
  text-decoration: underline;
}
.bold-word-panel {
  font-weight: bold;
}

.sb-dropdown {
  max-width: 300px;
}

.customers-toolbar {
  padding-left: 0px;
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}
.icon-users:before {
  content: "";
}
.newCustomerBanner {
  margin-bottom: 0;
}
.zendesk-spacer {
  margin-right: 8em;
}
.addNew {
  margin-right: 1rem !important;
}
.ruleName {
  min-width: 350px;
}

.h2header .heading .h2 {
  font-size: 10px;
}
.footertext {
  text-align: left;
  text-decoration: none;
  flex: 0 0 70%;
  color: #858fa0;
  font-weight: 400;
  padding-top: 0.25em;
  font-size: 0.85em;
}
.sb-panel-default {
  padding-bottom: 1em;
}
.emptystate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
</style>
