<template>
  <div>
    <div class="fulfillment-metrics-header">
      <h4>How would you like the shipping methods displayed?</h4>
      <p
        style="padding: 0px 15px"
      >Use rules to override the default values of how and when the shipping methods will display.</p>
      <div class="float-right"></div>
    </div>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="space-between" vs-w="12">
      <vs-col vs-w="8" vs-justify="flex-end" vs-align="flex-end">
        <sb-table
          :columnDefinitions="columnDefinitions"
          :rows="tableHelper.pagedData"
          :loadingPromise="loadingPromise"
          @sort="handleSort"
          :defaultSortColumn="defaultSortColumn"
          :defaultSortOrdering="defaultSortOrdering"
          class="sb-table-wrapper-MinHeight"
        >
          <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
            <div class="search-wrapper"></div>
            <sb-button variation="primary" @click="addRule()" @click.prevent>Add New Rule</sb-button>
            <!-- <popover
            :open="true"
            :titleText="'Add Rule'"
            :controlClass="'btn btn-primary dropdown-toggle'"
            :nonAdminOptions="this.nonAdminOptionsList"
             
            ></popover>-->
          </sb-toolbar>

          <sb-table-row slot="sb-table-row" slot-scope="{row}">
            <sb-table-cell>{{row.ruleName}}</sb-table-cell>

            <sb-table-cell>
              <router-link
                :to="{ name: 'Shipping Method Rules', params: {id: row.ruleId } }"
              >Edit Rule</router-link>
            </sb-table-cell>
            <sb-table-cell>
              <template>
                <sb-button
                  variation="warning"
                  size="extra-small"
                  @click="deleteRule(row.ruleId)"
                  @click.prevent
                >Delete</sb-button>
              </template>
            </sb-table-cell>
          </sb-table-row>
          <sb-table-empty-state slot="sb-table-empty-state" icon-name="products" label="No Rules">
            <p></p>
          </sb-table-empty-state>
        </sb-table>
      </vs-col>
      <vs-col vs-w="4" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
        <sb-panel variation="sub" style="flex-direction: column; max-width: 350px;">
          <h4>Tip!</h4>
          <p>
            There are some great ways to increase checkout cart values. To learn more about
            <span class="bold-word-panel">ShipBob Express</span> and how it works check
            <a
              style="color: rgb(41, 103, 165);text-decoration: underline;"
              href="https://shipbobsupport.force.com/Support/s/article/2-Day-Express"
              target="_blank"
            >this</a>
 out.
          </p>
        </sb-panel>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import Vue from "vue";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";
let ShippingMethods = require("@/enums/shippingMethods");

export default {
  props: ["title"],
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {
      loadingPromise: null,
      columnDefinitions: [
        { name: "Rule Name", field: "title", sortable: false },
        { name: "", field: "ruleId", sortable: false },
        { name: "", field: "ruleId", sortable: false }
      ],
      tableHelper: new tabler(this, 200),
      nonAdminOptionsList: [
        // {
        //   Id: 1,//look at the id matchs the c#
        //   Title: "Always set the Title or Description . . .",
        //   Callback: async _ => {
        //       var rule = ShippingMethods.default.DefaultRulesV2.find(f=> f.carrierServiceRuleType==1);
        //       this.addRule(rule);
        //     },
        //   isDisabled: _ => false
        // },
        {
          Id: 2, //look at the id matchs the c#
          Title: "Always rule such as 'Always set the Title . . .'",
          Callback: async _ => {
            var rule = ShippingMethods.default.DefaultRulesV2.find(
              f => f.carrierServiceRuleType == 2
            );
            this.addRule(rule);
          },
          isDisabled: _ => false
        },
        {
          Id: 3, //look at the id matchs the c#
          Title: "Conditional rule such as 'When Cart Value is . . .'",
          Callback: async _ => {
            var rule = ShippingMethods.default.DefaultRulesV2.find(
              f => f.carrierServiceRuleType == 3
            );
            this.addRule(rule);
          },
          isDisabled: _ => false
        }
      ]
    };
  },
  mounted() {
    this.loadingPromise = this.fetch(1);
    this.$root.$on("shippingMethodAdded", () => {
      this.loadingPromise = this.fetch(1);
    });
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    getAppLink() {
      return process.env.VUE_APP_ENDPOINT;
    },
    numberWithCommas(x) {
      return x == undefined
        ? x
        : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    update() {
      this.loadingPromise = this.fetch(1);
    },
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return item;
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    deleteRule(id) {
      if (this.tableHelper.receivedData.length == 1) {
        Vue.$toast.open({
          message: "At least one rule is required!",
          type: "error",
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      axiosInstance(
        "DELETE",
        process.env.VUE_APP_ENDPOINT + "shipping/methods/rules/" + id,
        null
      ).then(response => {
        if (response.status === 200) {
          Vue.$toast.open({
            message: "Rule Deleted!",
            type: "error",
            position: "bottom-right",
            duration: 5000
          });
          this.update();
        } else {
          Vue.$toast.open({
            message: "Error!",
            type: "error",
            position: "bottom-right",
            duration: 10000
          });
        }
      });
    },

    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();
        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "shipping/methods/rules",
          null
        ).then(response => {
          this.tableHelper.loadData(response.data.data.rules, p);
          this.$store.commit(
            "setShippingMethodsRules",
            this.tableHelper.receivedData
          );
          this.$root.$emit("finishedFetch");
          res(true);
        });
      });
    },
    addRule: function(rule) {
       this.$router.push({
            name: "Shipping Method Rules",
            params: { id: "draft" }
          });  
    }
  }
};
</script>
<style lang="scss" scoped>
// @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
.fulfillment-metrics-container {
  padding-bottom: 0px;
}
.link-more-details-paragraph {
  font-size: 15px;
  color: rgb(41, 103, 165);
  text-decoration: underline;
}
.bold-word-panel {
  font-weight: bold;
}

.sb-dropdown {
  max-width: 300px;
}

.customers-toolbar {
  padding-left: 0px;
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}
.icon-users:before {
  content: "";
}
.newCustomerBanner {
  margin-bottom: 0;
}
.zendesk-spacer {
  margin-right: 8em;
}
.addNew {
  margin-right: 1rem !important;
}
</style>