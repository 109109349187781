<template>
  <!-- :class="'dashboard-panel-'+panelSize" -->
  <div class="card dashboard-card dashboard-panel-medium">
    <div class="card-body">
      <div v-if="!disableHeader">
        <header class="vs-card-header" v-if="!headerSmall">
          <title-component
            :str="title"
            :header="header"  :paragraph="paragraph" :paragraph1="paragraph1" :paragraph2="paragraph2" :paragraph3="paragraph3"   :footer="footer"
            :ttPosition="tooltipPosition"
            :dataName="dataName"
          />
        </header>
        <header class="vs-card-header" v-else style="padding-bottom: 0px !important">
          <title-component
            :str="title"
             :header="header"  :paragraph="paragraph" :paragraph1="paragraph1" :paragraph2="paragraph2" :paragraph3="paragraph3"   :footer="footer"
            :ttPosition="tooltipPosition"
            :dataName="dataName"
          />
        </header>
      </div>
  
     
      <div>
        <component :is="kindTemplate" :data="data" />
      </div>
      <div v-if="!disableFooter" slot="footer">
        <vs-row vs-justify="flex-end">&nbsp;</vs-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "title",
    "data",
    "kindTemplate",
    "disableHeader",
    'header', 'paragraph','paragraph1','paragraph2','paragraph3', 'footer', 
    "tooltipPosition",
    "dataName",
    "headerSmall",
    'disableFooter'
  ]
};
</script>
<style scoped lang='scss'> 

 //see shipbobv2.scss
</style>