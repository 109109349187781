export default [ 
    {
        name: 'Shipping Settings',
        href: '/',
        icon: 'truck', 
        callback: function () {
          this.$router.push({ name: 'Shipping Settings' })
        },
        show: true
    }     
];
