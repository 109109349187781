import ShipBobAuthentication from '@shipbob/auth';

const AUTH_AUTHORITY = process.env.VUE_APP_AUTH_AUTHORITY
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID
const CLIENT_SCOPE = process.env.VUE_APP_CLIENT_SCOPE

const localAuthenticationInformation = {
  authority: AUTH_AUTHORITY,
  client_id: CLIENT_ID,
  post_logout_redirect_uri: `${location.protocol}//${location.host}/`,
  redirect_uri: `${location.protocol}//${location.host}/signin-callback`,
  response_type: 'code',
  scope: CLIENT_SCOPE
}

let localAuthService = null;

export function getAuthService() {
  if (localAuthService) {
    return localAuthService;
  }

  localAuthService = new ShipBobAuthentication(localAuthenticationInformation);
  return localAuthService;
}