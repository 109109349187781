<template>
  <div class="main-content">
    
    <div class="header-container">
      <base-header
        :heading="'When there is a Checkout Request event.'"
        :description="'Rules are a great way to modify a workflow of a process. '"
        slot="header"
      >
        <div slot="action">
          <sb-button variation="secondary" size="medium" @click="saveRule" @click.prevent>Save</sb-button>
          <!-- <sb-button
            variation="warning"
            size="extra-small"
            @click="deleteRule"
            @click.prevent
          >Delete</sb-button> -->
        </div>
      </base-header>
    </div>
    <div class="dashboard-container">
      <div class="transparent-div">
        <div>
 
   
</div>
        <vs-row   vs-justify="flex-start">
          <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <h5 class="propertyname">Rule Name</h5>

            </vs-col>
          <vs-col vs-w="10" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <sb-text-input
              class="input-long"
              v-model="this.item.ruleName"
              placeholder="Rule Name.."
              :type="text"
              :maxlength="90"
              @change="functionToChangeValue"
            ></sb-text-input>
          </vs-col>
        </vs-row>
        <br>
        <vs-row  vs-justify="flex-start" >
          <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="center">
            <h5 class="propertyname">Rule Type<info  style="margin-left:0px;" 
            :paragraph="'*Always True* Is a rule with no conditions.  We will always apply the actions regardless.'" 
            :paragraph1="'*Conditional Rule* Is a rule that has conditions.  If all the conditions are met then we will apply the actions.'" 
        ></info> </h5>
            <div></div>
            </vs-col>
          <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <sb-radio-button v-model="ruleType" value="Always" variation="primary" @input="ruleTypeChanged">Always True</sb-radio-button>
          </vs-col>
           <vs-col vs-w="2" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <sb-radio-button v-model="ruleType" value="When" variation="primary"  @input="ruleTypeChanged">Conditional</sb-radio-button>
          </vs-col>
            <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
             
          </vs-col>
        </vs-row>
      </div>
      <card-full-width
        :title="'How would you like the methods to be display?'"
        :kindTemplate="'condition-always-set'"
        :tooltipPosition="'right'"
        :disableHeader="true"
        :disableFooter="true"
        :data="this.item"
      />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axiosInstance from "../helpers/ws";
let ShippingMethods = require("@/enums/shippingMethods"); 
let RuleConditionTypesEnums = require("@/enums/ruleConditionType");

export default {
  components: {},
  data() {
    return {
      breakpoints: {
        wrap: el => el.width < 500,
        full: el => el.width >= 500
      },
      item: {},
      ruleType: ""
    };
  },
  created() {   
          
  },
  mounted()
  {
          if (this.$route.params.id == "draft") { 
            this.item={};
            this.ruleType="";
            let rule = Object.assign({},ShippingMethods.default.DefaultRulesV2.find(
              f => f.carrierServiceRuleType == 2
           )); 
           rule.actions=[];
            this.item = rule; 
            this.ruleType = this.item.ruleConditionTypeEnum; 
             
          }else{
             this.fetchRules(this.$route.params.id);
          } 
  },
  methods: {
    deleteRule() {
      var id = this.$route.params.id;
      axiosInstance(
        "DELETE",
        process.env.VUE_APP_ENDPOINT + "shipping/methods/rules/" + id,
        null
      ).then(response => {
        if (response.status === 200) {
          Vue.$toast.open({
            message: "Rule Deleted!",
            type: "error",
            position: "bottom-right",
            duration: 5000
          });
          this.$router.push({ name: "Shipping Settings" });
        } else {
          Vue.$toast.open({
            message: "Error!",
            type: "error",
            position: "bottom-right",
            duration: 10000
          });
        }
      });
    },
    saveRule() {
      var id = this.$route.params.id;
      if(this.item.ruleName=="")
      {
         Vue.$toast.open({
            message: "Please provide a Rule Name!",
            type: "error",
            position: "top-right",
            duration: 5000
          });
          return;
      }
      if(this.item.actions.length==0)
      {
         Vue.$toast.open({
            message: "At least one action is required!",
            type: "error",
            position: "top-right",
            duration: 5000
          });
          return;
      }
       if(this.item.conditions.length==0)
      {
         Vue.$toast.open({
            message: "At least one condition is required!",
            type: "error",
            position: "top-right",
            duration: 5000
          });
          return;
      }
      if (id=="draft")
      {
        this.post(this.item);
      }else{
        this.put(id,this.item);
      }
      
    },
    post(rule)
    {
      axiosInstance(
        "POST",
        process.env.VUE_APP_ENDPOINT + "shipping/methods/rules",
        null,
        rule
      ).then(response => { 
        if (response.status === 200) {
              Vue.$toast.open({
                message: "Rule Saved!",
                type: "info",
                position: "bottom-right",
                duration: 5000
              });
                this.$router.push({
                  name: "Shipping Method Rules",
                  params: { id: response.data.data }
                });  
               this.fetchRules(response.data.data);
            } 
       }).catch((error) => { 
           // Error 😨
        if (error.response && error.response.data.error) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
             Vue.$toast.open({
              message:error.response.data.error,
              type: "error",
              position: "bottom-right",
              duration: 10000
            });
        }   
      });
    },
    put(id,rule)
    { 
        axiosInstance(
            "PUT",
            process.env.VUE_APP_ENDPOINT + "shipping/methods/rules/" + id,
            null,
            rule
          ).then(response => {
            if (response.status === 200) {
              Vue.$toast.open({
                message: "Rule Saved!",
                type: "info",
                position: "bottom-right",
                duration: 5000
              });

               this.$router.push({
                  name: "Shipping Method Rules",
                  params: { id: response.data.data }
                });  
             
              this.fetchRules(response.data.data);
            } else {
              Vue.$toast.open({
                message: "Error!",
                type: "error",
                position: "bottom-right",
                duration: 10000
              });
            }
          }).catch((error) => { 
              // Error 😨
            if (error.response && error.response.data.error) {
                /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */
                Vue.$toast.open({
                  message:error.response.data.error,
                  type: "error",
                  position: "bottom-right",
                  duration: 10000
                });
            }   
          });
    },
    wrapStyles(el) {
      let baseStyles = "justify-content: space-between;";
      if (el.is.wrap)
        return `${baseStyles} flex-wrap: wrap; flex-direction: column-reverse;`;
      return `${baseStyles}`;
    },
    mainContentStyles(el) {
      if (el.is.wrap) return "";
      return "max-width: 900px;";
    },
    subPanelStyles(el) {
      const baseStyles = "flex-direction: column;";
      if (el.is.wrap) return `${baseStyles} margin-bottom: 1em;`;
      return `${baseStyles} max-width: 300px; margin-left: 2em;`;
    },
    fetchRules(id) {
      axiosInstance(
        "GET",
        process.env.VUE_APP_ENDPOINT + "shipping/methods/rules/" + id,
        null
      ).then(response => {
        this.item = response.data.data.rule; 
         this.ruleType=this.item.ruleConditionTypeEnum 
      });
    },
    functionToChangeValue({ type, target }) {
      this.item.ruleName = target.value;
    }  ,
    ruleTypeChanged(changed)
    {
         //clear conditions and change ruletype
         this.item.ruleConditionTypeEnum=changed;
         if (changed=="When")
         {
            this.item.conditions=[];
         }
         else if (changed=="Always")
         {          
           var rule = ShippingMethods.default.DefaultRulesV2.find(f=> f.carrierServiceRuleType==2); 
            this.item.conditions=rule.conditions;
            
         }  
    }
  }
};
</script>
<style scoped lang='scss'>
@import "@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss";
@import "@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss";
.main-content {
  background-color: #f2f3f5;
}
.card {
  background-color: transparent;
  border: transparent;
  box-shadow: inherit;
}
.dashboard-container {
  padding-top: 0px;
}
.dashboard-card {
  //max-width: 1000px;
  margin: auto;
}
.input-long {
  min-width: 450px;
}
.transparent-div {
  margin: auto;
  width: 100%;
  flex: 0 0 94%;
  -webkit-box-flex: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  padding-left: 1.25rem;
}
 
.propertyname
{
  margin-top: auto;
  margin-bottom: auto;
}

</style>
 