import { render, staticRenderFns } from "./ShippingSettings.vue?vue&type=template&id=21cb8745&scoped=true"
import script from "./ShippingSettings.vue?vue&type=script&lang=js"
export * from "./ShippingSettings.vue?vue&type=script&lang=js"
import style0 from "./ShippingSettings.vue?vue&type=style&index=0&id=21cb8745&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21cb8745",
  null
  
)

export default component.exports