<template>     
      <div class="main-content">
        <div class="header-container"> 
	   <base-header :heading="'Shipping Settings'"
                 :description="''"
                 slot="header"
                 > 
				 <div slot="action">
        <div class="btn-group btn-group-nav" role="group">
          <div class="btn-group btn-group-nav" role="group">
           <div>
		   </div> 
          </div>
        </div>
      </div>
    	</base-header>
		</div>
       <div class="dashboard-container"> 
					 
				  <card-full-width 
							:title="'Which Shipping Methods would you like to display?'" 
							:kindTemplate="'shipping-methods'" 
							:tooltipPosition="'right'"
							:disableHeader="true"
							:disableFooter="true"
						/>

            <card-full-width 
							:title="'How would you like the methods to be display?'" 
							:kindTemplate="'shipping-methods-rules'" 
							:tooltipPosition="'right'"
							:disableHeader="true"
							:disableFooter="true"
						/>

             <card-full-width 
							:title="'How wodfduld you like the methods to be display?'" 
							:kindTemplate="'enable-store-carrier-services'" 
							:tooltipPosition="'right'"
							:disableHeader="true"
							:disableFooter="true"
						/>
            
		</div>
		</div> 
	 
</template>
<script>   

export default {
components: {
	 
  
  },
	data() {
		return { 
			 showTransitionModal: false ,
			   breakpoints: {
          wrap: el => el.width < 500,
          full: el => el.width >= 500
		}
		} 
	},
	mounted() {
	 
	},
	methods: {
		 
		 wrapStyles (el) {
          let baseStyles = 'justify-content: space-between;'
          if (el.is.wrap) return `${baseStyles} flex-wrap: wrap; flex-direction: column-reverse;`
          return `${baseStyles}`
        },
        mainContentStyles (el) {
          if (el.is.wrap) return ''
          return 'max-width: 900px;'
        },
        subPanelStyles (el) {
          const baseStyles = 'flex-direction: column;'
          if (el.is.wrap) return `${baseStyles} margin-bottom: 1em;`
          return `${baseStyles} max-width: 300px; margin-left: 2em;`
        }
	}
	  
}
</script>
<style scoped lang='scss'>
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_dashboard.scss';
  @import '@/../assets/scss/shipbob-custom/components/dashboard/_setup-dashboard.scss'; 
.main-content {
  background-color: #f2f3f5
} 

</style>
 