<template>
  <vs-col
    vs-w="11"
    vs-type="flex"
    vs-justify="flex-start"
    vs-align="center"
    v-if="row!== undefined && row.ActionTypeName != 'RemoveShippingMethodActionModel' || row.ShipOptionsId==35  "
  >Unsupported action please contact support.</vs-col>
  <vs-col v-else-if="row!== undefined" vs-w="11">
    
  <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
          Hide &nbsp;
        <b>Shipping Method</b>&nbsp;
        </vs-col>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <sb-dropdown
          :options="ShippingMethodsList"
          v-model="row.ShippingOptionId"
          placeholder="Shipping Method.."
          class="select-size-200"
        >
          <sb-selected-option
            slot="selection"
            slot-scope="{ selected }"
          >{{getSelectedLabel(ShippingMethodsList,selected)}}</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option.value">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
      </vs-col>
  </vs-row>
  </vs-col>
</template>
<script>
let ShippingMethods = require("@/enums/shippingMethods");
let ActionsList = require("@/enums/actionsList");

export default {
  props: ["row"],

  data() {
    return {
      ShippingMethodsList: ShippingMethods.default.ShippingMethods,
      actionsList: ActionsList.default.ActionsList,
      actionModel: ActionsList.default,
      ActiveShippingMethods: [],
      useDefaultTitle: true,
      useDefaultDescription: true 
    };
  },
  mounted() {  
  },
  computed: {},
  methods: {
    getSelectedLabel(options, value) {
      var option = options.find(x => x.value === value);
      if (option == undefined || option.text == "") {
        option = options.find(x => x.value === value.value);
      }
      return option !== undefined ? option.text : "";
    } 
  }
};
</script>
<style lang="scss" scoped>
.input-long {
  min-width: 300px;
}
.select-size-200 {
  margin-left: 0px !important;
}
</style>