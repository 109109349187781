<template>
  <vs-col
    vs-w="11"
    vs-type="flex"
    vs-justify="flex-start"
    vs-align="center"
    v-if="row!== undefined && row.ActionTypeName != 'CarrierServiceMarkupModel' || row.ShipOptionId==35 "
  >Unsupported action Please contact support.</vs-col>
  <vs-col v-else-if="row!== undefined" vs-w="11">
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
        <div>
          &nbsp;
          <b>{{getSelectedLabel(actionsList,row.ActionTypeName)}}</b>
          &nbsp;by&nbsp;&nbsp;
        </div>
      </vs-col>
      <vs-col vs-w="4" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <sb-dropdown
          :options="MarkupByList"
          v-model="isDollarAmountModel"
          placeholder="Markup Type.."
          class="select-size-200"
        >
          <sb-selected-option
            slot="selection"
            slot-scope="{ selected }"
          >{{ getSelectedLabel(MarkupByList,selected) }}</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option.value">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>&nbsp;&nbsp;  
        <vs-input v-model="row.MarkUpAmount"></vs-input>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
          Apply to &nbsp;
        <b>Shipping Method</b>&nbsp;</vs-col>
      <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
        <sb-dropdown
          :options="ShippingMethodsList"
          v-model="row.ShipOptionId"
          placeholder="Shipping Method.."
          class="select-size-200"
        >
          <sb-selected-option
            slot="selection"
            slot-scope="{ selected }"
          >{{getSelectedLabel(ShippingMethodsList,selected)}}</sb-selected-option>
          <sb-options>
            <sb-option slot-scope="{ option }" :value="option.value">{{ option.text }}</sb-option>
          </sb-options>
        </sb-dropdown>
      </vs-col>
    </vs-row>
  </vs-col>
</template>
<script>
let ShippingMethods = require("@/enums/shippingMethods");
let ActionsList = require("@/enums/actionsList");

export default {
  props: ["row"],

  data() {
    return {
      ShippingMethodsList: ShippingMethods.default.ShippingMethods,
      actionsList: ActionsList.default.ActionsList,
      actionModel: ActionsList.default,
      isDollarAmountModel: 0,
      MarkupByList: [
        {
          value: 1, //true
          text: "Currency Amount"
        },
        {
          value: 2, //false there is an issue with the dropdown with false values
          text: "Percent"
        }
      ]
    };
  },
  mounted() {
    if (this.row.isDollarAmount == true) {
      this.isDollarAmountModel = 1;
    } else if (this.row.isDollarAmount == false) {
      this.isDollarAmountModel = 2;
    } 
  },
  watch: {
    isDollarAmountModel: function(val) {
      if (val == 1) {
        this.row.isDollarAmount = true;
      } else if (val == 2) {
        this.row.isDollarAmount = false;
      }
    }
  },

  methods: {
    getSelectedLabel(options, value) {
      var option = options.find(x => x.value === value);
      if (option == undefined || option.text == "") {
        option = options.find(x => x.value === value.value);
      }
      return option !== undefined ? option.text : "";
    }
  }
};
</script>
<style lang="scss" scoped>
.select-size-200 {
  margin-left: 0px !important;
}
</style>