<template>
    <div>
        <vs-row vs-w="12">
            <vs-col vs-w="12" vs-justify="flex-start">
                <h2 class="h2" style="text-align:center" :class="colorClass"><span v-if="simbol !== 'undefined'">{{simbol}}</span>{{value==0?"-":numberWithCommas(value)}}</h2>
                <p v-if="type === undefined" class="h2-sub" style="text-align:center">{{subtitle}}</p>
                <p v-if="type === 'normal-text'" style="text-align:center">{{subtitle}}</p>
            </vs-col>
        </vs-row>
    </div>
</template>
<script>
export default {
    props:['value', 'subtitle', 'simbol', 'colorClass', 'delta', 'type'],    
    methods: {
        numberWithCommas(x) {
            return x==undefined?x: x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    } 
}
</script>

