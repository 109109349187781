import { authorizationGuard } from '../router/guards/AuthGuard';
import Vue from 'vue';
import Router from 'vue-router';
import { routes } from './routes';

Vue.use(Router);

function initializeRouter() {
  const router = new Router({
    mode: 'history',
    routes
  });

  router.beforeEach(authorizationGuard);

  return router;
}

export default initializeRouter;
