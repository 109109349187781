
import { authRoutes } from './auth';
import { expressUiRoutes } from './expressUi';

const routes = [
  ...authRoutes,
  ...expressUiRoutes,
];

export { routes };
