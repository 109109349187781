<template>
  <div>
    <div class="fulfillment-metrics-header">
      <h4>
        Which Shipping Methods would you like to enable for cart checkout?
        <info
          style="margin-left:5px;"
          :paragraph="'These shipping methods will be available at checkout based on the cart items, quantities, weight and destination.'"
        ></info>
      </h4>
      <p
        style="padding: 0px 15px"
      >Shipping rates can be setup to be displayed on your cart checkout page.</p>
      <div class="float-right"></div>
    </div>
    <vs-row vs-w="12" vs-justify="center" vs-align="center">
      <vs-col vs-w="9">
        <!-- <order-turn-around-time-search-bar></order-turn-around-time-search-bar> -->
      </vs-col>
      <vs-col vs-w="3"></vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="space-between" vs-w="12">
      <vs-col vs-w="12" vs-justify="flex-end" vs-align="flex-end">
        <sb-table
          :columnDefinitions="columnDefinitions"
          :rows="tableHelper.pagedData"
          :loadingPromise="loadingPromise"
          @sort="handleSort"
          :defaultSortColumn="defaultSortColumn"
          :defaultSortOrdering="defaultSortOrdering"
          class="sb-table-wrapper-MinHeight"
        >
          <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
            <div class="search-wrapper"></div>
            <popover
              :open="true"
              :titleText="'Add Shipping Method'"
              :controlClass="'btn btn-primary dropdown-toggle'"
              :nonAdminOptions="this.nonAdminOptionsList"
            ></popover>
          </sb-toolbar>
          <sb-table-row slot="sb-table-row" slot-scope="{row}">
            <sb-table-cell>{{row.shipOptionId}}</sb-table-cell>
            <sb-table-cell>{{row.name}}</sb-table-cell>
            <sb-table-cell>
               {{row.description}} 
            </sb-table-cell>
            <sb-table-cell>
              <template>
                <div v-if="!isNaN(row.defaultPrice)">${{row.defaultPrice}}</div>
                <div v-else> <a
                      :href="getMerchantAppLink()+'App/Merchant/#/Pricing'"
                      style="color: rgb(41, 103, 165);text-decoration: underline;"
                      target="_blank"
                      rel="noopener noreferrer"
                    >{{row.defaultPrice}}</a>
                    </div>
              </template>
              </sb-table-cell>
            <sb-table-cell>
              <template>
                <sb-button @click="removeMethod(row)" variation="warning" size="small">Remove</sb-button>
              </template>
            </sb-table-cell>
          </sb-table-row>
          <sb-table-empty-state
            slot="sb-table-empty-state"
            icon-name="products"
            label="No Shipping Methods"
          >
            <p>Select a Shipping Method from the dropdown above.</p>
          </sb-table-empty-state>
        </sb-table>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import Vue from "vue";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";
let ShippingMethods = require("@/enums/shippingMethods");

export default {
  props: ["title"],
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {
      selected2: { text: "Select Shipping Method..", value: 0 },
      ShippingMethodsList: ShippingMethods.default.ShippingMethods,
      loadingPromise: null,
      columnDefinitions: [
        { name: "ShipOption ID", field: "shipOptionId", sortable: false }, 
        { name: "Default Title", field: "name", sortable: false }, 
        {
          name: "Default Description",
          field: "description",
          sortable: false
        },
        {
          name: "Default Shipping Price",
          field: "defaultPrice",
          sortable: false
        },
        {
          name: "",
          field: "",
          sortable: false
        }
      ],
      tableHelper: new tabler(this, 20),
      nonAdminOptionsList: []
    };
  },
  mounted() {
    this.loadingPromise = this.fetch(1);
    this.populateAddShippingMethodButtonList();
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    populateAddShippingMethodButtonList()
    {
      this.nonAdminOptionsList = this.ShippingMethodsList.map(shipoption => {
        return {
          Id: shipoption.value,
          Href: `${shipoption.value}`,
          Title: `(${shipoption.value}) ${shipoption.shipOptionMappingText}`,
          Callback: async (id) => {
            const shipoptionId = parseInt(id);
            const method = this.ShippingMethodsList.find(f => f.value == id);
            this.nonAdminOptionsListAddMethod(method);
          },
          isDisabled: _ => false
        }
      })
    },
    nonAdminOptionsListAddMethod(method)
    {
      let alwaysSetTitle = Object.assign({}, ShippingMethods.default.DefaultRulesV2.find(f => f.carrierServiceRuleType == 1));
      let addChangeNameAction = [30,36].indexOf(method.value) !== -1;
      alwaysSetTitle.actions = [];
      
      let config = {
        ShipOptionId: method.value,
        ShippingMethod: method.shipOptionMappingText,
        DefaultRules: []
      };

      if (addChangeNameAction) {
        alwaysSetTitle.actions.push( 
          {
            "ActionTypeName":"ChangeShippingNamingActionModel",
            "ShipOptionsId":method.value,
            "ShipOptionNameChange": method.shipOptionMappingText,
            "ShipOptionDescriptionChange": method.description
          }
        );
        config.DefaultRules.push(alwaysSetTitle);
      }

      this.addMethod(config);
    },
    getMerchantAppLink() {
      return process.env.VUE_APP_SHIPBOB_LINK;
    },
    numberWithCommas(x) {
      return x == undefined
        ? x
        : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    update() {
      this.loadingPromise = this.fetch(1);
    },
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) { 
      return {
        shipOptionId: item.shipOptionId,
        name: item.name,
        tnt: item.tntDescription,
        description: item.description,
        defaultPrice : item.defaultPrice
      };
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    },
    addMethod(config) {
      if (config.ShipOptionId == 0) {
        Vue.$toast.open({
          message: "Select a Shipping Method",
          type: "warning",
          position: "bottom-right",
          duration: 5000
        });
        return;
      }
      //check if we have it already
      if (
        this.tableHelper.receivedData.filter(
          w => w.shipOptionId == config.ShipOptionId
        ).length > 0
      ) {
        Vue.$toast.open({
          message: "You already have " + config.ShippingMethod,
          type: "info",
          position: "bottom-right",
          duration: 5000
        });
        return;
      }

      axiosInstance(
        "POST",
        process.env.VUE_APP_ENDPOINT +
          "shipping/methods/" +
          config.ShipOptionId,
        null,
        config
      ).then(response => {
        if (response.status === 200) {
          Vue.$toast.open({
            message:
              "Shipping Method " +
              config.ShippingMethod +
              " successfully added!",
            type: "info",
            position: "bottom-right",
            duration: 5000
          });
        } else {
          Vue.$toast.open({
            message: "Shipping Method " + config.ShippingMethod + " Error!",
            type: "error",
            position: "bottom-right",
            duration: 10000
          });
        }

        this.update();
        this.$root.$emit("shippingMethodAdded");
      });
    },
    removeMethod(record) {
      axiosInstance(
        "DELETE",
        process.env.VUE_APP_ENDPOINT +
          "shipping/methods/" +
          record.shipOptionId,
        null
      ).then(response => {
        if (response.status === 200) {
          Vue.$toast.open({
            message: "Removed Shipping Method " + record.name + "!",
            type: "info",
            position: "bottom-right",
            duration: 5000
          });
        } else {
          Vue.$toast.open({
            message: "Shipping Method " + record.name + " Error!",
            type: "error",
            position: "bottom-right",
            duration: 10000
          });
        }
        this.update();
      });
    },
    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();
        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "shipping/methods",
          null
        ).then(response => {
          this.tableHelper.loadData(response.data.data, p);
          this.$store.commit("setShippingMethods", response.data.data);
          this.$root.$emit("finishedFetch");
          res(true);
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
// @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
.fulfillment-metrics-container {
  padding-bottom: 0px;
}
.link-more-details-paragraph {
  font-size: 15px;
  color: rgb(41, 103, 165);
  text-decoration: underline;
}
.bold-word-panel {
  font-weight: bold;
}

.sb-dropdown {
  max-width: 300px;
}

.customers-toolbar {
  padding-left: 0px;
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}
.icon-users:before {
  content: "";
}
.newCustomerBanner {
  margin-bottom: 0;
}
.zendesk-spacer {
  margin-right: 8em;
}
.addNew {
  margin-right: 1rem !important;
}
.sb-table-wrapper-MinHeight {
  min-height: 300px;
}
</style>