<template>
  <div>
    <div class="fulfillment-metrics-header">
      <h4>
        Which store would you like to activate ShipBob Express for?
         
      </h4>
      
      <div class="float-right"></div>
    </div> 

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="space-between" vs-w="12">
      <vs-col vs-w="8" vs-justify="flex-end" vs-align="flex-end">
        <sb-table
          :columnDefinitions="columnDefinitions"
          :rows="tableHelper.pagedData"
          :loadingPromise="loadingPromise"
          @sort="handleSort"
          :defaultSortColumn="defaultSortColumn"
          :defaultSortOrdering="defaultSortOrdering"
        class="sb-table-wrapper-MinHeight">
          <sb-toolbar slot="sb-toolbar" class="customers-toolbar">
            <div class="search-wrapper">
               
            </div>
            
            
          </sb-toolbar>
          <sb-table-row slot="sb-table-row" slot-scope="{row}">
            <sb-table-cell style="padding-left: 0px;">   
              <template>  
              <div v-if="row.platformSourceId==1" class="store-box imgShopify" > </div>
              <div v-if="row.platformSourceId!=1" class="store-box imgWoo" > </div>            
            </template>
            </sb-table-cell> 
            <sb-table-cell>
              <template> 
              <a
                      :href="row.storeURL"
                      style="color: rgb(41, 103, 165);text-decoration: underline;"
                      target="_blank"
                      rel="noopener noreferrer"
                    >{{row.storeName}}</a>
              </template>
              </sb-table-cell>
            <sb-table-cell>
              <template>
                <sb-button @click="enableStore(row)" variation="secondary" size="small" v-if="row.platformSourceId==1 && (row.carrierServicesExists==false || row.carrierServicesEnabled==false)">Enable</sb-button>
                <sb-button @click="disableStore(row)" variation="warning" size="small" v-if="row.platformSourceId==1 && (row.carrierServicesEnabled==true)">Disable</sb-button>
                <!-- <sb-button @click="deleteStore(row)" variation="warning" size="extra-small" v-if="row.platformSourceId==1 && (row.carrierServicesExists==true)">Permanently Delete</sb-button> -->
                <div v-if="row.platformSourceId!=1">No action needed.<br> Just install the Express plugin.</div>
              </template>
            </sb-table-cell>
          </sb-table-row>
          <sb-table-empty-state
            slot="sb-table-empty-state"
            icon-name="products"
            label="No Stores Setup"
          >
            <p>Go to Quick Setup to connect a store!</p>
          </sb-table-empty-state>
        </sb-table>
      </vs-col>
      <vs-col vs-w="4" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
        <sb-panel variation="sub" style="flex-direction: column; max-width: 350px;">
          <h4>Heads up!</h4>
          <p>
            Prior to setup, For
            <span class="bold-word-panel">Shopify</span> accounts, be sure to activate Carrier Calculated Rates on your Shopify store. You can use this
            <a
              style="color: rgb(41, 103, 165);text-decoration: underline;"  href="https://shipbobsupport.force.com/Support/s/article/Shopify-2-day-setup"
            >walkthrough</a> to find out how.
          </p>
          <p>
            For
            <span class="bold-word-panel">WooCommerce</span> accounts, be sure to install the ShipBob Express plugin on your WooCommerce store. You can use this
            <a
              style="color: rgb(41, 103, 165);text-decoration: underline;" href="https://shipbobsupport.force.com/Support/s/article/WooCommerce-2-day-setup"
            >walkthrough</a> to find out how.
          </p>
        </sb-panel>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import axiosInstance from "../../../helpers/ws";
import moment from "moment";
import Vue from "vue";
import tabler from "@/utils/tablehelper";
import PaginationWrapper from "@/components/utils/PaginationWrapper";
let ShippingMethods = require('@/enums/shippingMethods');

export default {
  props: ["title"],
  components: {
    "pagination-wrapper": PaginationWrapper
  },
  data() {
    return {  
      ShippingMethodsList: ShippingMethods.default.ShippingMethods,
      loadingPromise: null,
      columnDefinitions: [
        { name: "Channel", field: "platformName", sortable: true },
        {
          name: "Store",
          field: "storeName",
          sortable: true
        },  
        {
          name: "",
          field: "",
          sortable: false
        }
      ],
      tableHelper: new tabler(this, 50) 
    };
  },
  mounted() {
    this.loadingPromise = this.fetch(1);
  },
  computed: {
    defaultSortColumn() {
      return null;
    },
    defaultSortOrdering() {
      return null;
    }
  },
  methods: {
    getMerchantAppLink()
    {
      return process.env.VUE_APP_SHIPBOB_LINK;
    },
    numberWithCommas(x) {
      return x == undefined
        ? x
        : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    update() {
      this.loadingPromise = this.fetch(1);
    },
    handleSort({ sortColumn, descending }) {
      this.tableHelper.handleSort({
        sortColumn: sortColumn,
        descending: descending
      });
    },
    map(item) {
      return item;
    },
    handleFetchPage(p) {
      this.loadingPromise = this.tableHelper.loadData(
        this.tableHelper.receivedData,
        p.page
      );
    }, 
    enableStore(record) {



      axiosInstance(
        "POST",
        process.env.VUE_APP_ENDPOINT +
          "shipping/stores/" +
          record.storeId,
        null
      ).then(response => { 
        if (response.status === 200) {
          Vue.$toast.open({
            message:
              "Carrier Services Enabled!",
            type: "info",
            position: "bottom-right",
            duration: 5000
          });
        }  
        this.update();
      }).catch((error) => { 
           // Error 😨
        if (error.response && error.response.data.error) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
          //  console.log(error.response.data);
            
             Vue.$toast.open({
              message:error.response.data.error,
              type: "error",
              position: "bottom-right",
              duration: 10000
            });
        }   
      });
    },
       disableStore(record) {
      axiosInstance(
        "PUT",
        process.env.VUE_APP_ENDPOINT +
          "shipping/stores/" +
          record.storeId,
        null
      ).then(response => {
        if (response.status === 200) {
          Vue.$toast.open({
            message:
              "Carrier Services Disabled!",
            type: "info",
            position: "bottom-right",
            duration: 5000
          });
        } else {
          Vue.$toast.open({
            message: " Error!",
            type: "error",
            position: "bottom-right",
            duration: 10000
          });
        }
        this.update();
      });
    },
    deleteStore(record) {
      axiosInstance(
        "DELETE",
        process.env.VUE_APP_ENDPOINT +
          "shipping/stores/" +
          record.storeId,
        null
      ).then(response => {
        if (response.status === 200) {
          Vue.$toast.open({
            message:
              "Carrier Services Deleted!",
            type: "info",
            position: "bottom-right",
            duration: 5000
          });
        } else {
          Vue.$toast.open({
            message: " Error!",
            type: "error",
            position: "bottom-right",
            duration: 10000
          });
        }
        this.update();
      });
    },
    fetch: async function(p) {
      await new Promise(res => {
        this.tableHelper.preLoadReset();
        axiosInstance(
          "GET",
          process.env.VUE_APP_ENDPOINT + "shipping/stores",
          null
        ).then(response => { 
          this.tableHelper.loadData(response.data.data, p); 
          res(true);
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
// @import '@/../assets/scss/shipbob-custom/components/dashboard/_panel-fulfillment-metrics.scss';
.fulfillment-metrics-container {
  padding-bottom: 0px;
}
.link-more-details-paragraph {
  font-size: 15px;
  color: rgb(41, 103, 165);
  text-decoration: underline;
}
.bold-word-panel {
  font-weight: bold;
}

.sb-dropdown {
  max-width: 300px; 
}

.customers-toolbar {
  padding-left: 0px;
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}

.customers-toolbar {
  .title {
    margin: 0;
  }
  .btn {
    margin-left: 20px;
  }
  .search-wrapper {
    flex-grow: 1;
    margin-left: 0px;
    .input-container {
      max-width: 200px;
    }
  }
  @media only screen and (max-width: 681px) {
    .search-wrapper {
      flex-basis: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
    button {
      margin-top: 1em;
    }
  }
}
.icon-users:before {
  content: "";
}
.newCustomerBanner {
  margin-bottom: 0;
}
.zendesk-spacer {
  margin-right: 8em;
}
.addNew {
  margin-right: 1rem !important;
}
.sb-table-wrapper-MinHeight{
  min-height: 250px;;
}
.store-box 
{ 
   background: #fff;  
    -moz-transition: box-shadow linear .3s;
    -o-transition: box-shadow linear .3s;
    -webkit-transition: box-shadow linear .3s;
    transition: box-shadow linear .3s;
    
}
.imgShopify
{
 background-image: url('/img/storeSprites.png');  
  width: 120px;height: 40px;background-position: 0px 400px;
}
.imgWoo
{
 background-image: url('/img/storeSprites.png');  
  width: 120px;height: 40px;background-position: 0px 515px;
}

</style>