import { getAuthService } from '@/auth';
import store from '@/store';
import toastr from "toastr";

export async function authorizationGuard(to, _from, next) {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const token = await getAuthService().getToken();
 
  if ((requiresAuth && !token && to.path !== '/')) {
    console.error('Trying to anonymously access protected route', to.fullPath);
    next({ name: 'ExpressUi.Auth' });
    return;
  }
  else if (requiresAuth && token) {

    try {
      let isAdmin = store.getters.isAdmin;
      if(isAdmin === null)
      {
        await store.dispatch('isAdmin');
        isAdmin = store.getters.isAdmin;
      }

      if(isAdmin) {
        await getAuthService().renewToken();
      }
      else {
        toastr.error('You do not have access to login.');
        await store.dispatch('resetState');
        await getAuthService().logout();
        next({ name: 'ExpressUi.Auth' });
      }
    }
    catch(ex)
    {
      await store.dispatch('resetState');
      await getAuthService().logout();
      next({ name: 'ExpressUi.Auth' });
    }
  }
  next();
}
