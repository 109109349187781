<template>
  <div class="header">
    <mq-layout mq="xl+">
      <div class="header">
       <div class="navbar side-bar-brand-header">
        <vs-row  class="logo" >
          <vs-col  vs-w="5">
          <a href="/shipping">
            <img src="https://d33wubrfki0l68.cloudfront.net/221426b90a11dfca9e3a2a51195fa5ea4ec42a4a/2cf63/images/logo.svg"> 
          </a> 
          </vs-col>
             <vs-col vs-w="6">
              <SbBadge variation="primary" style="text-transform: inherit; color: #2470af;  background-color: #ecf0f1;">Express</SbBadge>
               </vs-col>
          </vs-row>
        </div>
      </div>
    </mq-layout>
    <mq-layout :mq="['md', 'lg']">
      <nav class="navbar side-bar-brand-header">
        <a class="logo justify-content-center" href="/">
          <!-- <img src="https://d33wubrfki0l68.cloudfront.net/221426b90a11dfca9e3a2a51195fa5ea4ec42a4a/2cf63/images/logo.svg"> -->
          <span class="align-middle icon-ShipBob-Logomark"></span>
        </a>
      </nav>
    </mq-layout>
    <mq-layout mq="sm">
      <div class="branded-header-sm">
        <nav>
          <a href="/shipping" class="logo-sm">
            <img src="https://d33wubrfki0l68.cloudfront.net/221426b90a11dfca9e3a2a51195fa5ea4ec42a4a/2cf63/images/logo.svg" alt="ShipBob">
        </a>
        <a href="#" class="btn hamberger-menu" @click.prevent="hamburgerClick()">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </a>
        </nav>
      </div>
    </mq-layout>
  </div>
</template>

<script>
export default {
  name: 'BrandedHeader',
  methods: {
    hamburgerClick () {
      this.$emit('openSideNav')
    }
  }
}
</script>
 

<style lang="scss" scoped>

.navbar.side-bar-brand-header .logo
{
 padding-bottom: 20px;
}

.sb-badge 
{ 
  color: transparent;
  background-color: #ecf0f1;
  text-transform: inherit;
  font-weight: bold;
}

.branded-header-sm {
    background: #2470af;
    position: relative;
    float: left;
    display: block;
    max-width: 100%;
    text-align: center;
    width: 100%;
    padding: 0;
    height: 3.625em;
    min-width: 256px;
    z-index: 30;
}

.logo-sm{
    display: inline-block;
    float: none;
    margin: .9em 0 .55em -.5em;

    img {
      max-height: 28px;
    }
}

.hamberger-menu {
  display: block;
  left: 1em;
  position: absolute;
  top: 0;
  background: transparent;

.line {
    width: 40px;
    height: 4px;
    background-color: #ecf0f1;
    display: block;
    margin: 8px auto;
    transition: all .3s ease-in-out
  }

.line:active:first-child {
    transform: translateY(12px) rotate(45deg);
  }
}

</style>

