import axios from 'axios';
import {getAuthService}  from '@/auth'


axios.interceptors.request.use(async request => {
    const access_token = await getAuthService().getToken();

    if(access_token){
        request.headers.Authorization = 'Bearer ' + access_token;
    } else {
        this.$router.push("Login");
    }

    return request;
}, function (error) {
    // Do something with request error
    if (error.response.status === 401) {
        this.$router.push("/");
    }
    return Promise.reject(error);
});

let axiosInstance = (method, uri, extraParams = {}, formData = new FormData()) => {
    let defaultHeaders = {}

    if(method == "GET"){
        return axios.get(uri, {
            // headers: {},
            headers: defaultHeaders,
            // headers: { 'Authorization': 'Bearer 1234' },
            params: extraParams
        });
    } else if(method == "DELETE"){
            return axios.delete(uri, {
                // headers: {},
                headers: defaultHeaders,
                // headers: { 'Authorization': 'Bearer 1234' },
                params: extraParams
            });
    } else if(method == "POST") {
        return axios.post(uri, formData, {
            headers: defaultHeaders
        });
    } else if (method == "PUT") {
        return axios.put(uri, formData, {
            headers: defaultHeaders
            // headers: { 'Authorization': 'Bearer 1234' }
        });
    }
};

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

export default axiosInstance;