import HttpWrapper from '../utils/http/httpWrapper'

const SHIPBOB_USERMAN_API = process.env.VUE_APP_SHIPBOB_AUTHENTICATE
const SHIPBOB_DASHBOARD = process.env.VUE_APP_SHIPBOB_SITE

export default class UserService {
  static noop () {}

  static urls () {
    return {       
      forgotPassword: SHIPBOB_DASHBOARD + '/Account/DoForgotPassword',
      login: SHIPBOB_DASHBOARD + '/Account/ValidateUser',
      logout: SHIPBOB_DASHBOARD + '/Account/LogOut',
      newPassword: SHIPBOB_DASHBOARD + '/Account/ForgotPassword',
      about: SHIPBOB_USERMAN_API + '/api/onboarding/qualifier1',
      getDetailedUser: SHIPBOB_USERMAN_API + '/api/userData/fetchDetailedUserData',
      getLoggedInAndImpersonatedUser: SHIPBOB_USERMAN_API + '/api/userData/fetchLoggedInAndImpersonatedBasicUserData',
      setUserStep: SHIPBOB_USERMAN_API + '/api/onboarding/savestep',
      markUserPrelaunch: SHIPBOB_USERMAN_API + '/api/onboarding/markUserPrelaunch',
      phone: SHIPBOB_USERMAN_API + '/api/onboarding/phone',
      business: SHIPBOB_USERMAN_API + '/api/onboarding/qualifier2',
      launchDate: SHIPBOB_USERMAN_API + '/api/onboarding/launchdate',
      pricingAssignmentExperiment: SHIPBOB_USERMAN_API + '/api/onboarding/pricingAssignmentExperiment', 
      upsertLandingPageEmail: SHIPBOB_USERMAN_API + '/api/onboarding/upsertLandingPageEmail',
      removeLandingPageEmail: SHIPBOB_USERMAN_API + '/api/onboarding/removeLandingPageEmail'
    }
  }

  static async forgotPassword (payload) {
    const request = {
      Url: this.urls().forgotPassword,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  }

  static async newPassword (payload) {
    const request = {
      Url: this.urls().newPassword,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  }

  static async login (payload) {    
    const request = {
      Url: this.urls().login,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  }

  static async logout (payload) {
    const request = {
      Url: this.urls().logout,
      Data: {
        ...payload
      }
    }
    return await HttpWrapper.Post(request)
  } 

  static async getUser () {
    const request = {
      Url: this.urls().getUser
    }
    return await HttpWrapper.Get(request, null, null, true)
  }

  static async getDetailedUser () {
    const request = {
      Url: this.urls().getDetailedUser
    }
    return await HttpWrapper.Get(request, null, null, true)
  }

  static async getLoggedInAndImpersonatedUser () {
    const request = {
      Url: this.urls().getLoggedInAndImpersonatedUser
    }
    return await HttpWrapper.Get(request, null, null, true)
  } 

}
